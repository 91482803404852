import React from "react";
import ReactGA from "react-ga4";

import About from "../components/about/About";
import Contact from "../components/Contact";
import Navbar from "../components/nav/Navbar";
import Projects from "../components//projects/Projects";
import WhatWeDo from "../components/what-we-do/WhatWeDo";
import Whoweare from "../components/whoweare/Whoweare";
import Featured from "../components/featured/Featured";
import Featured_B from "../components/featured_b/Featured_B";
import Team from "../components/team/Team";
import Industries from "../components/industries/Industries";
import Analysis from "../components/analysis/Analysis";
import Services from "../components/services/Services"
import StayConnected from "../components/stay-connected/StayConnected";
import ValueProps from "../components/value-props/ValueProps";

import { Waypoint } from 'react-waypoint';

ReactGA.initialize('G-ZRR6N02WDC');

export default function Landing() {
  return(
      <main className="text-gray-400 font-body">
        
        <Navbar />
        <About />
        <ValueProps />
        <Whoweare />
        {/* <Featured /> */}
        {/* <Featured_B /> */}
        {/* <Projects /> */}
        {/* <Analysis /> */}
        <StayConnected />
        {/* <Industries /> */}
        {/* <WhatWeDo /> */}
        {/* <Team /> */}
        {/* <Contact /> */}
        {/* <Services /> */}
      </main>
  );
}
