// src/components/Projects.js

// import { CodeIcon } from "@heroicons/react/solid";
import React from "react";
//import { projects } from "../../data";
//import mapSvg from "./map-data.svg"
import "./StayConnected.css";
import EmailSub from "../email-subscribe/EmailSub";
import { Waypoint } from 'react-waypoint';


export default function StayConnected() {

  return (
    <section id="sec-stay-connected" className="flex flex-wrap text-gray-400 bg-neutral-900 body-font bg-repeat mx-auto">
      <article className="flex flex-col text-center lg:px-96 md:px-48 px-16 py-24">
          <h3 className="text-4xl tracking-tight font-semibold text-white mb-4">Stay up to date with Topologik's latest</h3>

          <article>
            <p className="text-md text-white mb-4">
            Sign up to receive monthly updates on the latest in technology, projects, and research from the team at Topologic. You can unsubscribe at anytime, but please let us know what you want to see!
            </p>

            <EmailSub />

            {/* <form className="w-full mx-auto" name="emailSub" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
              <div className="flex md:flex-row md:flex-nowrap flex-wrap content-center">
                <input type="hidden" name="emailSub" value="contact" />
                <input className="md:basis-2/3 bg-white w-full text-gray-700 md:mr-3 mr-0 mb-4 md:mb-0 py-4 px-8 focus:outline-none" type="email" name="email" placeholder="Enter Email Address" aria-label="Email Address"/>
                <input className="md:basis-1/3 w-full bg-white flex-shrink text-neutral-900 py-4" type="Submit" value="Sign Up" />
              </div>
            </form> */}

          </article>

      </article>
    </section>
  );
}