import { useEffect, useState } from 'react';

const RotatingText = () => {
  // Array of terms to rotate through
  const texts = [
    "Climate Science",
    "Artificial Intelligence",
    "Environmental Research",
    "Sustainability",
    "Renewable Energy"
  ];
  
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState("opacity-100");

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Start fade-out animation
      setFade("opacity-0");

      // After the fade-out duration, change text and fade in
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFade("opacity-100");
      }, 500); // This timeout should match the transition duration below
    }, 5000); // Change term every 3 seconds

    return () => clearInterval(intervalId);
  }, [texts.length]);

  return (
    <h1 className="text-brandblue-800 sm:text-left font-display font-light text-5xl my-4">
      Your{" "}
      <span className={`font-semibold transition-opacity duration-500 ${fade}`}>
        {texts[index]}
      </span>{" "}
      Experts
    </h1>
  );
};

export default RotatingText;
