// src/components/Projects.js

// import { CodeIcon } from "@heroicons/react/solid";
import React from "react";
//import { projects } from "../../data";
//import mapSvg from "./map-data.svg"
import "./Whoweare.css";
import { Waypoint } from 'react-waypoint';

import whowearegraphic from "./who-we-are.png"



export default function Projects() {
  return (
    <section id="projects" className="text-gray-400 bg-teal-900 bg-repeat">
      <div className="mx-auto">

        <div className="flex flex-row">
          <div className="lg:px-80 px-16 py-24 text-center">
            <h3 className="text-4xl tracking-tight font-regular text-white mb-8 px-16 leading-snug">We're a team of scientists and technologists passionate about building a healthier and safer planet</h3>
            <p className="text-lg text-gray-50">
            Advances in technology allow for new insights into land, environmental conditions, and project statuses. We uses the latest in cutting-edge technology and research to provide land-owners a path forward toward efficient land management.
            </p>
          </div>
        </div>


        {/* <div className="flex flex-wrap -m-4 mb-16 ">
          <div className="sm:w-auto w-100 p-4">
            <div className="flex relative">
              <div className="w-3/5 h-full">
                <p>Mission Statement #1</p>
                <h3 className="text-gray-50 text-2xl font-medium leading-10">Topologic helps enterprises use insights and sensors to take advantage of the full potential of geographic data. Leveraging a scientist’s method and an artist’s sensitivity, we work with clients to extract knowledge from their data and use analytical models to reveal patterns that help make smarter business decisions.</h3>
              </div>

              <div className="w-2/5 h-full">
                <img className="mapSvg" src={mapSvg} alt="Map Data Analysis"></img>
              </div>

            </div>
          </div>
        </div>

        <div className="flex flex-wrap -m-4">
          <div className="sm:w-auto w-100 p-4">
            <div className="flex flex-row-reverse">
              <div className="w-3/5 h-full">
                <p>Mission Statement #2</p>
                <h3 className="text-white text-2xl font-medium leading-10">We give your business the compass it needs to find new directions in GIS. Our handpicked crew of strategists, cartographers, data modelers, and engineers specializes in custom solutions. We’re a small company founded on big ideas, a sophisticated GIS partner for every industry.</h3>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="flex flex-wrap -m-4">
          {projects.map((project) => (
            <a
              href={project.link}
              key={project.image}
              className="sm:w-auto w-100 p-4">
              <div className="flex relative">
                <img
                  alt="gallery"
                  className="absolute inset-0 w-full h-full object-cover object-center"
                  src={project.image}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-50 hover:opacity-100">
                  <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                    {project.subtitle}
                  </h2>
                  <h1 className="title-font text-lg font-medium text-white mb-3">
                    {project.title}
                  </h1>
                  <p className="leading-relaxed">{project.description}</p>
                </div>
              </div>
            </a>
          ))}
        </div> */}


      </div>
    </section>
  );
}