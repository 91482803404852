// src/components/Navbar.js

// import { ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";
import logo from './topo-logo.svg';
import logoAlt from './logo-k.svg';
import logoBlk from './logo-blk.svg'
import logomark from './topo-logomark.svg';
import './nav.css'

export default function Navbar() {
  return (
    <header className="bg-white md:sticky top-0 z-20">
      <div className="container mx-auto flex flex-wrap px-2 py-4 flex-col md:flex-row items-center">
        <a className="title-font font-medium text-gray-900 md:mb-0">
          <a href="#about">
            <img className="nav-logo" src={logoAlt} alt="Topologic Logo"></img>
          </a>
        </a>
        <nav className="text-neutral-900 md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-gray-700 flex flex-wrap items-center font-medium text-base justify-center">
          <a href="/#featured" className="mr-5 hover:underline">
            Projects
          </a>
          {/* <a href="/#featured_b" className="mr-5 hover:underline">
            Aerial Imaging
          </a>
          <a href="/mobileairq" className="mr-5 hover:underline">
            MobileAirQ
          </a> */}
          {/* <a href="#testimonials" className="mr-5 hover:underline">
            Geospatial Analysis
          </a> */}
          <a href="#team" className="mr-5 hover:underline">
            About
          </a>
          <a href="#team" className="mr-5 hover:underline">
            Work With Us
          </a>
        </nav>
        <a
          href="#sec-stay-connected"
          className="inline-flex items-center text-sm rounded-3xl font-semibold bg-indigo-700 text-white py-4 px-8 focus:outline-none hover:underline hover:bg-neutral-900 hover:text-white mt-4 md:mt-0">
          Get Early Access
          {/* <ArrowRightIcon className="w-4 h-4 ml-1" /> */}
        </a>
      </div>
    </header>
  );
}