// src/components/Projects.js

// import { CodeIcon } from "@heroicons/react/solid";
import React from "react";
//import { projects } from "../../data";
//import mapSvg from "./map-data.svg"
import { Waypoint } from 'react-waypoint';
import featuredImage from './featured-image.png';
import sensorImage from './sensors-bg.png'



export default function ValueProps() {
  return (
    <section id="featured" className="flex flex-row flex-wrap text-neutral-500 bg-white">

      <div className="flex flex-col px-16 py-16 mx-auto items-center">

          <h3 className="text-4xl text-center tracking-tight font-semibold text-teal-800 mb-16">Our Data</h3>
          {/* <p className="text-center text-lg text-gray-800 mb-16 px-48">
          Our low-cost sensors can be placed anywhere to track the current and historical conditions of your land. With readings for Temperature, Relative Humidity, Light Exposure, and Stress Factors, you'll always know the state of your land. 
          </p> */}

          <div className="flex flex-wrap -mx-4 text-center">
            {/* First Item */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              {/* <img src="your-image-url.jpg" alt="Description" className="w-full rounded-lg" /> */}
              <h3 className="text-xl font-semibold text-teal-800">Transportation</h3>
              <p className="text-lg mt-2">Your paragraph text here explaining the value proposition.</p>
            </div>

            {/* Second Item */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              {/* <img src="your-image-url.jpg" alt="Description" className="w-full rounded-lg" /> */}
              <h3 className="text-xl font-semibold text-teal-800">Future Climate Risk</h3>
              <p className="text-lg mt-2">Your paragraph text here explaining the value proposition.</p>
            </div>

            {/* Third Item */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              {/* <img src="your-image-url.jpg" alt="Description" className="w-full rounded-lg" /> */}
              <h3 className="text-xl font-semibold text-teal-800">Environmental Burden</h3>
              <p className="text-lg mt-2">Your paragraph text here explaining the value proposition.</p>
            </div>

            {/* Fourth Item */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              {/* <img src="your-image-url.jpg" alt="Description" className="w-full rounded-lg" /> */}
              <h3 className="text-xl font-semibold text-teal-800">Social Vulnerability</h3>
              <p className="text-lg mt-2">Your paragraph text here explaining the value proposition.</p>
            </div>

            {/* Fifth Item */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              {/* <img src="your-image-url.jpg" alt="Description" className="w-full rounded-lg" /> */}
              <h3 className="text-xl font-semibold text-teal-800">Climate & Disaster Risk</h3>
              <p className="text-lg mt-2">Your paragraph text here explaining the value proposition.</p>
            </div>

            {/* Sixth Item */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              {/* <img src="your-image-url.jpg" alt="Description" className="w-full rounded-lg" /> */}
              <h3 className="text-xl font-semibold text-teal-800">Total Index Scores</h3>
              <p className="text-lg mt-2">Your paragraph text here explaining the value proposition.</p>
            </div>
          </div>

          

          {/* create a 3x2 grid with flex for 6 value propositions */}
          

          <a href="#contact" class="items-center font-medium mt-16 rounded-3xl bg-teal-800 p-4 px-8 text-white">
          Contact for Early Access!
          </a>
      
      </div>

    </section>
  );
}