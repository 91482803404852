// src/components/About.js
import React from "react";
import './about.css';
import backgroundVideo from "./topo-bg-vid.mp4";
import topologo from './topo-logomark.svg';

import RotatingText from '../RotatingText/RotatingText'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
// const element = <FontAwesomeIcon icon={faCoffee} />


export default function About() {
  return (
    <section id="about" className="bg-gray-50">
      {/* <video autoPlay loop muted id='video' playsinline="true" disablePictureInPicture="true" className="mt-0 lg:-mt-24">
          <source src={backgroundVideo} type='video/mp4'/>
      </video> */}

      <div className="container mx-auto flex pt-24 pb-16 flex-col relative text-center">
        <div className="lg:flex-grow md:flex-grow flex flex-col mb-16 text-center">

          {/* <img src={topologo} alt="Topologik Logo" className="mx-auto w-32 h-32" /> */}
          
          {/* <h1 className="text-indigo-700 sm:text-left font-display font-light text-5xl my-4">
            Your <span className="font-semibold">Climate Science</span> Experts
          </h1> */}

          <RotatingText />

          {/* <div className="title-font text-center md:text-6xl text-5xl font-display font-medium text-neutral-900 tracking-normal leading-normal">
            <h1>Environmental knowledge</h1>
            <h1>for any application</h1>
          </div> */}



          <h2 className="text-neutral-700 sm:text-left font-display font-light text-3xl my-4">
            We study the impacts of Carbon Credits and their ongoing benefits for society.
          </h2>
      
          <div className="flex justify-left my-8 gap-8">
            <a
                href="#projects"
                className="items-center inline-flex text-white font-medium bg-brandblue-800 hover:bg-brandblue-800 hover:text-white py-4 px-8 focus:outline-none text-md rounded-3xl ">
                Our Methods<i className="pl-4"><FontAwesomeIcon icon={faAngleRight}/></i>
              </a>
              <a
                href="#projects"
                className="items-center inline-flex text-white font-medium bg-brandblue-800 hover:bg-brandblue-800 hover:text-white py-4 px-8 focus:outline-none text-md rounded-3xl ">
                Our Team<i className="pl-4"><FontAwesomeIcon icon={faAngleRight}/></i>
              </a>
          </div>
        </div>

      </div>
    
      {/* <div className="md:absolute relative pr-8 md:bottom-36 bottom-8 md:mx-auto mx-0 right-0 bg-neutral-900 font-medium text-white p-4">
          <p>Footage Shot on a DJI Mini 3</p>
          <a href="#featured_b" className="text-sm underline cursor-pointer">Learn More</a>
      </div> */}

    </section>
  );
}